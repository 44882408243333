<template>
<v-layout wrap >

    <v-snackbar  v-model="snackbar" >
        {{ titulo_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text dark v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>

    <loading :active.sync="isLoading" :can-cancel="false" 
    background-color="#FFF" color = "#A90501" loader="spinner"
    :is-full-page="fullPage">
    </loading>


    <v-layout align-center justify-center wrap >

        <v-flex xs12 sm6 md6 lg6  >

            <v-layout wrap >
                <v-flex md12 sm12 xs12 lg12  v-for="app in miCarrito" :key="app.id" >
                    <v-card
                    class="elevation-8 ma-1 rounded-lg pb-2"
                    outlined
                    >
                    <v-layout class="pt-2 mt-2 pr-3">
                        <v-flex xs5 sm5 md5 lg5 >
                            <v-img
                            contain
                            :aspect-ratio="16/9"
                            max-width='150px'
                            :src="getImageUrl(app.imagen)" 
                            >
                            </v-img>
                        </v-flex>

                        <v-flex xs7 sm7 md7 lg7 class="text-left mt-4 mr-6" shrink >
                            <div style="font-size:18px" >{{ app.nombre }}  </div>
                            <div color="indigo" style="font-size:14px">${{ app.valor | numeral('0,0') }} x {{app.cant}} </div>                    
                        </v-flex>

                        <v-flex xs1 sm1 md1 lg1 >
                            <div>
                                <v-btn icon color="red" @click="deleteCart(app)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                            <div >
                                <v-btn color="secondary" icon @click="editar(app)">
                                    <v-icon >mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>

            <v-btn class="mt-2"  elevation="8" @click="$router.push({ name: 'productos' })" 
                  :color="botonFlotante" text rounded block>
                 <v-icon >mdi-arrow-left-thick</v-icon> Seguir comprando
            </v-btn>

        </v-flex>
    </v-layout>


    <!-- Último Paso -->

    <v-dialog
        v-model="dialog"
        persistent
        fullscreen
        scrollable
       >      
        <v-card class="pa-2">
          <v-card-title class="font-weight-bold white--text" :class="ultimoPaso">
            <v-flex md6 sm12 xs12 offset-md3 >
                <v-layout align-end justify-end>
                    <v-btn dark icon @click="dialog = false"  >
                        <v-icon >mdi-close</v-icon>
                    </v-btn>
                </v-layout>
                Último Paso
            </v-flex>
          </v-card-title>
          
          <v-divider class="mb-4"></v-divider>


          <v-card-text class="px-6">
           <v-flex md6 sm10 xs12 offset-md3 text-md-center >

               <v-form ref="formPersonal" v-model="valid" lazy-validation>
                    <v-text-field  v-model="datos.nombre" 
                    prepend-icon="mdi-account"
                    color="danger"
                    hint="Necesitamos saber como llamarte"
                    placeholder="Nombre y Apellido *"  
                    :rules="fieldRules" ></v-text-field>

                    <v-text-field  v-model="datos.celular" 
                    prepend-icon="mdi-cellphone"
                    hint="Necesitamos tu número de contacto"
                    placeholder="Celular *" 
                    :rules="rulesCel" 
                    type="number"
                    @keypress="IsNumber($event)"
                    prefix="+569" ></v-text-field>
                </v-form>

                <v-layout row class="pl-3 my-3" style="font-size:18px">
                    <div class="text-left" >Medio de Entrega</div>
                </v-layout>

                <v-radio-group
                row
                column
                large
                v-model="datos.entrega" 
                mandatory
                >
                    <v-radio label="RECOGER" value="0"
                    color="success" off-icon="mdi-cancel"
                    on-icon="mdi-storefront"  @click="changeEntrega" :disabled="perfil.modo_ventas.includes(0) ? false : true">
                        <template v-slot:label>
                            <div style="font-size:20px"><strong >RETIRO</strong>
                            </div>
                        </template>
                    </v-radio>

                    <v-radio value="1" color="success"
                    on-icon="mdi-bike" off-icon="mdi-cancel"
                    @click="changeEntrega" :disabled="perfil.modo_ventas.includes(1) ? false : true">
                        <template v-slot:label>
                            <div style="font-size:20px"><strong >DELIVERY</strong>
                            </div>
                        </template>
                    </v-radio>
                </v-radio-group>

                <v-layout row class="pl-3 my-3" style="font-size:18px">
                    <div class="text-left pr-3" >Fecha de entrega o retiro </div>
                    <div class="text-right red--text">{{ fechaDespacho }}</div>
                </v-layout>

                <v-form ref="formEntrega" v-model="valid2" lazy-validation>
                <div v-show="ubicacion">
                    <v-divider ></v-divider>
                    <div class="text-left my-3">Dirección</div>
                    <v-layout row class="px-3 mb-3">
                        <v-flex md10 sm12 xs12 >
                            <v-text-field  v-model="datos.calle" 
                            hint="Ej: 33 y media oriente, 15 norte"
                            label="Calle *"  
                            :rules="fieldRules"
                            clearable
                            class="mr-1" ></v-text-field>
                        </v-flex>
                        <v-flex md2 sm12 xs12 >
                            <v-text-field  v-model="datos.numero" 
                            hint="Número casa o departamento"
                            label="Número *"  
                            :rules="rule"
                            clearable
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="px-3 mb-4">
                        <v-flex md12 sm12 xs12 >
                            <v-text-field  v-model="datos.referencia" 
                            hint="Ayuda al repartidor a encontrar tu domicilio"
                            placeholder="Referencias"  
                            clearable
                            :rules="rules"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-btn color="secondary" small text block @click="linkMixin(linkUbicacion)">
                            <v-icon >mdi-google-maps</v-icon> Revisa en Google Maps tu ubicación 
                        </v-btn>
                    </v-layout>

                </div>
                
                </v-form>

               

                <v-select  
                class="mt-2"
                v-model="formadepago" 
                :items="listaFop" 
                item-text="fop" 
                item-value="id" 
                label="Forma de Pago" 
                outlined
                dense
                required
                :rules="rule"
                @change="changeFop"
                ></v-select>

                <div v-show="formaTransf" class="py-1">
                    <div class="text-left">Cuenta para depósito</div>
                    <v-divider class="my-2"></v-divider>
                    <v-layout justify-center>
                     <div v-html="perfil.banco"></div>
                    </v-layout>
                </div>
                 <div v-show="formaEfectivo">
                     <v-form ref="formPago" v-model="valid3" lazy-validation>
                    <div class="text-left">¿ Con cuánto vas a pagar ?</div>
                    <v-divider></v-divider>
                    <v-layout>
                        <v-text-field  
                        outlined
                        dense
                        v-model="datos.efectivo" 
                        placeholder="De esta manera tenemos el cambio listo para ti"
                        label="Ingrese el monto *" 
                        :rules="rule"
                        type="number"
                        @keypress="IsNumber($event)"
                        ></v-text-field>
                    </v-layout>
                    </v-form>
                </div>
                
                <v-textarea
                    outlined
                    counter
                    label="¿ Quieres aclarar algo del pedido ?"
                    value=""
                    v-model="datos.comentario"
                    :rules="rulesTxt"
                ></v-textarea>

                <v-row row class="px-2">
                    <v-text-field 
                    dense
                    :disabled=cupon
                    v-model="datos.cupon" 
                    hint="Accede a descuentos, ingresando tu cupon"
                    label="Cupon de descuento" 
                    >
                    <template v-slot:append>
                        <v-btn
                        class="mr-2"
                        color="danger"
                        :disabled=cupon
                        @click="validaCupon()"
                        outlined >
                            VALIDAR
                        </v-btn>
                    </template>
                    </v-text-field>
                </v-row>

                <v-divider class="my-3"></v-divider>
                <v-layout justify-start>
                    <div v-html="perfil.condiciones"></div>
                </v-layout>
                   
		    </v-flex> 
              <v-row class="mt-2">
                <v-col cols="12" xs="12" sm="10" md="9" align="right">
                    <div>
                        <span >Subtotal</span>
                        <span> $ {{totalizador | numeral('0,0')}} </span>
                    </div>
                    <div>
                        <span>Descuento</span>
                        <span> $ {{ descuento | numeral('0,0')}} </span>
                    </div>
                    <span style="font-size:20px">Total</span>
                    <span style="font-size:20px"> $ {{ total | numeral('0,0')}} </span>
                </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
              <v-flex md6 sm12 xs12 offset-md3 text-md-center>
                <v-btn block color="success" large dark class="mt-2" @click="realizarPedido()">
                    PEDIR VIA WHATSAPP <v-icon class="ml-2">mdi-whatsapp</v-icon>
                </v-btn>
              </v-flex>
          </v-card-actions>
        </v-card>
    </v-dialog>


    <dialogCargando :dialogLoad="dialogLoad"></dialogCargando>

    <dialogEditarProducto 
    @modificar="modificar" 
    @cerrar-editar="cerrarEditar" 
    :info="info" 
    :dialogEdit="dialogEdit"/>

    <footerDetalle :totalizador="totalizador" @finalizar="dialog = true"></footerDetalle>



</v-layout>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import footerDetalle from '../components/footerDetalle.vue'
import dialogCargando from '../components/dialogCargando.vue'
import dialogEditarProducto from '../components/dialogEditarProducto.vue'
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
    components :{
        footerDetalle, 
        dialogCargando,
        dialogEditarProducto
    },
    computed: {
        ...mapState(['formaPagos','miCarrito', 'perfil', 'miHorario', 'totalizador']),
        total(){
            return this.totalizador - this.descuento;
        },
        linkUbicacion(){
            let url = this.linkMap+this.datos.calle+'+'+this.datos.numero
            return url.trim().replace(/(\s{1,})/g,'+').replace('++','+');
        },
        listaFop(){
            let fop = this.formaPagos;
            let arr = [];
            this.comboFop.forEach(function (e) {
                if(fop.includes(e.id) ){
                    arr.push({ 'id' : e.id, 'fop': e.fop})
                }                       
            })
            return arr;
        },
        ubicacion() {
            if(this.datos.entrega == 1){
                if(this.perfil.modo_ventas.includes(1) == true){
                    return true
                }
            }else{
                if(this.perfil.modo_ventas.includes(0) == false){
                    return true
                }
            }
            return false

        }
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            loading : false,
            dialog : false,
            fechaDespacho : '',
            fentrega : '',
            dialogEdit : false,
            dialogLoad : false,
            info : [],
            datos : {
                nombre  : '',
                celular : '',
                entrega : '',
                calle : '',
                numero : '',
                referencia : '',
                efectivo : '',
                comentario : '',
                tienda : 1,
                cupon : '',
                dscto : 0
            },
            valid: true,
            valid2: true,
            valid3: true,
            formaTransf : false,
            formaEfectivo : false,
            formas_pagos: '',
            comboFop : [
                { id : '2', fop : 'Efectivo' },
                { id : '3', fop : 'Tarjeta (Débito - Crédito)' },
                { id : '4', fop : 'Transferencia' },
                { id : '5', fop : 'Junaeb' },
                { id : '6', fop : 'Pago Fácil' },
            ],
            formadepago : '',
            snackbar : false,
            titulo_snackbar: "",
            cupon : false,
            descuento : 0,
            urlwsp : '',
            //Validaciones
            rule : [v => !!v || 'Campo Requerido'],
            fieldRules: [
                v => !!v || 'Campo Requerido',
                v => v.length <= 60 || 'Campo debe contener menos de 50 caractéres',
            ],
            rules: [ v => v.length <= 100 || 'Campo debe contener menos de 100 caractéres'],
            rulesTxt: [v => v.length <= 300 || 'Campo debe contener menos de 300 caractéres'],
            rulesCel : [
                v => !!v || 'Campo Requerido',
                v => v.length == 8 || 'Celular no válido. Ejemplo 97817045',
            ],
        }
    },
    mounted(){
        let fecha =  moment(this.miHorario.fecha).add(this.miHorario.despacho, 'days') 
        this.fechaDespacho = moment(fecha).format("DD-MM-YYYY");
        this.fentrega = new Date(fecha);
    },
    methods : {
        ...mapMutations(['delCarrito', 'setTotaliza', 'setCantidad']),
        ...mapActions(['eliminarDelCarro', 'actualizaElCarro']),
        changeEntrega(){
            this.datos.entrega == 1
        },
        async validaCupon(){
            if (this.datos.cupon == '') return

            var token = localStorage.getItem('token');
            var url = this.$server + 'cupon/valida';
            this.isLoading = true
            var dataObj = { "cupon" : this.datos.cupon, "empresa" : this.$empresa, "sucursal" : this.perfil.sucursal };
            await this.axios.post(url, 
            dataObj, 
            {
                headers: {
                    'auth-token':  token
                },
            })
            .then((response) => {  
                if(response.data.status == 200){
                    this.datos.dscto = response.data.data;
                    this.cupon = true
                    this.titulo_snackbar = "Descuento aplicado"
                    this.snackbar = true
                    this.setTotaliza()
                    this.aplicaDscto()
                }
                this.isLoading = false
            })
            .catch((e)=>{
                console.log('error terminos' + e);
                this.isLoading = false
            })
        },
        aplicaDscto(){
            this.descuento = (this.totalizador * this.datos.dscto) / 100;
        },
        changeFop(){
            // EF
            if(this.formadepago == 2){
               this.formaEfectivo = true
               this.formaTransf = false
               return
            }
            //TC o Junaeb
            if(this.formadepago == 3 || this.formadepago == 5 || this.formadepago == 6){
               this.formaEfectivo = false
               this.formaTransf = false
               return
            }
            //Transf
            if(this.formadepago == 4){
                this.formaEfectivo = false
                this.formaTransf = true
                return
            }
            this.formaEfectivo = false
            this.formaTransf = false
        },
        deleteCart(item){
            this.eliminarDelCarro(item.id)
        },
        editar(item){
            this.info = item
            this.dialogEdit = true
        },
        modificar(item, contador, observacion){
            this.dialogEdit = false
            this.$store.dispatch("actualizaElCarro", [item, contador, observacion]);
        },
        cerrarEditar(){
            this.dialogEdit = false
        },
        async cargando(){
            this.dialogLoad = false
            let ans = await this.postFinalizar();
            if(ans){
                this.cupon = false
                Swal.fire({
                    title: 'Un paso más y terminas !!',
                    footer: "Tu pedido esta listo para ser enviado",
                    imageUrl: this.logo_home, 
                    imageWidth: 100,
                    imageHeight: 100,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false,
                    confirmButtonColor: '#4CAF51',
                    confirmButtonText: 'Enviar mensaje via whatsapp'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.open(this.urlwsp)
                        Swal.fire({
                            icon: 'success',
                            title: 'Gracias!',
                            text: 'Pedido Realizado',
                        })
                        this.delCarrito([])
                        this.setCantidad()
                        this.setTotaliza()
                        this.$router.push({ name: 'productos' })
                    }
                })
            }
            this.isLoading = false;
        },
        async postFinalizar(){

            this.isLoading = true;
            var token = localStorage.getItem('token');
            var url = this.$server + 'finaliza/pedido';
            
            var dataObj = {
                "personal" : this.datos,
                "carrito"  : this.miCarrito,
                "fecha"    : this.fentrega,
                "fop"      : this.formadepago,
                "empresa"  : this.$empresa,
                "sucursal" : this.perfil.sucursal,
                "ubicacion": this.linkUbicacion
            };
            
            try {
                return await this.axios.post(url, 
                dataObj, 
                {
                    headers: {
                        'auth-token':  token
                    },
                }).then(res => {
                    this.dialog = false;
                    if(res.data.error == null){
                      this.enviaWsp(res.data.data._id)
                      return true;
                    }
                    if(res.data.error == 401){
                        Swal.fire("Tienda Cerrada","Volvemos pronto ...","error");
                    }
                    this.isLoading = false;
                    return false
                }).catch(error => {
                    this.isLoading = false;
                    this.dialog = false;
                    Swal.fire("Error !","Hubo un problema, vuelva a intentar","error");
                    console.log(error)
                });
            } catch (error) {
                console.log(error)
            }
            
        },
        async realizarPedido(){

            if (this.miCarrito.length == 0 ) {
                Swal.fire("MAL !", "Carrito sin productos", "error");
                return
            }

            if(this.$refs.formPersonal.validate() ){
                let rsp = false

                if(this.datos.entrega == 0){ //recoger
                    if(this.formadepago == 2 ){
                        if( this.$refs.formPago.validate() ){
                            rsp = true
                        }
                    }
                    if(this.formadepago == 3 || this.formadepago == 4 || this.formadepago == 5){
                        rsp = true
                    }
                }else{
                    if( this.$refs.formEntrega.validate() ){
                        if(this.formadepago == 2 ){
                            if( this.$refs.formPago.validate() ){
                                rsp = true
                            }
                        }
                        if(this.formadepago == 3 || this.formadepago == 4 || this.formadepago == 5){
                            rsp = true
                        }
                    }
                }

                if(rsp){
                    const sql = await this.asqQuestion()
                    if(sql){
                        this.dialogLoad = true
                        setTimeout(this.cargando,4000); 
                    }
                }else{
                    this.titulo_snackbar = "Revise campos requeridos en rojo"
                    this.snackbar = true;
                }
            }else{
                this.titulo_snackbar = "Revise campos requeridos en rojo"
                this.snackbar = true;
            }
        },
        async asqQuestion(){
            return await Swal.fire({
            title: '¿Deseas confirmar el pedido?',
			icon: "warning",
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
            cancelButtonColor: '#F44336',
			confirmButtonColor: '#4CAF50',
            reverseButtons: true,
            footer: 'Recuerda esperar confirmación del vendedor antes de pagar'
            }).then((result) => {
                if (result.isConfirmed) {
                    return true
                } 
                return false
            })
        },
        enviaWsp(orden){

            let ordenFormat = (orden || '').toUpperCase().substr(18,6)
            this.urlwsp = ''
            let pedido = ''
            let pie = ''
            let fecha = "Fecha delivery o retiro *"+this.fechaDespacho+"*";
            let celular = "(56)9"+this.datos.celular;
            let totalVenta = parseInt(this.totalizador) - parseInt(this.descuento);
            let ubicacion = "";

            let servicio = this.datos.entrega == 0 
            ? " y quisiera hacer un pedido para *recoger*.%0A"
            : " y quisiera hacer un pedido hacia *" + this.datos.calle + " " + this.datos.numero + "* / " + this.datos.referencia + ".%0A";

            let telefono = "Mi número celular es *"+celular+"*%0A%0A";

            let text = "---- Pedido " + "*"+ordenFormat+"*" + " ---- %0A%0A Hola " + this.$appName + " mi nombre es *" + this.datos.nombre + "*" + servicio + telefono;
            let cupon = this.datos.dscto > 0 ? "Cupon descuento : *"+this.datos.cupon+"*%0A" : '';
            let fop = this.fopTextoWsp()
            let comment  = "Comentario : " + this.datos.comentario;
            
            Object.values(this.miCarrito).forEach(carro => {
                pedido+= "- " + carro.cant + " x " + carro.nombre + " ($ " + carro.total + ") %0A" + " Obs: " + carro.obs + "%0A";
            });

            pie = "Subtotal : $ " + this.totalizador + "%0A";
            pie += "Descuento : $ " + this.descuento + "%0A";
            pie += "*Total: $" + totalVenta+"*";

            if(this.datos.entrega != 0){
                ubicacion = "*%0A%0AUbicación " + process.env.VUE_APP_SERV+'/map/'+orden;
            }

            this.urlwsp = "https://wa.me/569"+this.perfil.whatsapp+"?text=" + text + pedido + "%0A" + fecha + "%0A" + comment + "%0A" + fop + "%0A" + cupon + "------------------" + "%0A%0A" + pie + ubicacion;        
        },
        fopTextoWsp(){
            if(this.formadepago == 2){
                return "Pagaré con $" + this.datos.efectivo + " en *Efectivo*";
            }
            if(this.formadepago == 3){
                return "Pagaré con *Tarjeta*"
            }
            if(this.formadepago == 4){
                return "Pagaré con *Transferencia*"
            }
        }
    }
}
</script>
