<template>
<v-app  class="myFont">
  <SideNav ></SideNav>
  <v-main style="padding-top:0px" > 
    <v-container fluid > 
        <router-view ></router-view>
    </v-container>
  </v-main>
  </v-app>
</template>

<script>

import SideNav from './components/sideNav'

export default {
  name: 'App',
  components: {
    SideNav
  },
  data(){
    return {

    }
  }
};
</script>
<style scoped src="./assets/styles/style.css"></style>


