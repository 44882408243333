<template>
        <v-footer  app  inset padless tile color="white" class="justify-center">
        <v-card width="1100" class="elevation-8" >
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div style="font-size:20px">Total</div>
                  </v-list-item-title>
                    <v-list-item-subtitle>
                    Pedido
                    </v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer></v-spacer>
              <v-list-item-icon>
                <div style="font-size:26px">$ {{totalizador | numeral('0,0')}}</div>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
            <v-flex text-center>
                <v-btn block dark :color="botonConfirmarPedido" large @click="$emit('finalizar')" > 
                 CONFIRMAR PEDIDO 
                 </v-btn>
            </v-flex>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    props : {
        totalizador : Number
    }
}
</script>