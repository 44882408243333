import Vue from 'vue'
import Main from './Main.vue'
import router from './router'
import store from './store'
require('./assets/styles/style.css')
import vuetify from './plugins/vuetify' // path to vuetify export

import GlobalVars from './globalVars'
Vue.use(GlobalVars)

import GlobalComponents from './globalComponents'
Vue.use(GlobalComponents)

Vue.config.productionTip = false 

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Main)
}).$mount('#app')
