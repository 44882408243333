import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#de1d21', 
          secondary: '#383432', 
          danger: '#D33C47',
          anchor: '#F9DEC7',
          white: '#ffffff',
          topbar: '#383432',
          background: '#C94938',
          orange: '#036838',
          breadcrumbs: '#ffffff',
          carrito : '#6CBA88'
        },
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  })

export default vuetify;
