export const misMixins = {
	data(){
		return {
            googleMaps : 'https://www.google.es/maps?q=',
            linkWsp : 'https://api.whatsapp.com/send?phone=569',
            linkMap : 'https://www.google.com/maps/search/',
            rutaImage : this.$urlExt + '/img/products/',
            rutaImageCat : this.$urlExt + '/img/categories/',
            logo_side : this.$urlExt + '/img/logo_login.jpg',
            imagen_cabecera : this.$urlExt + '/img/pos.jpg',
            logo_home : this.$urlExt + '/img/logoTransparente.png',
            urlHorario : this.$server2 + 'data/horarios_'+ this.$empresa + localStorage.getItem('sucursal') + ".json",
            botonHome : 'secondary',
            cabecera : 'secondary',
            botonCarro : 'primary',
            avatarHome : 'primary',
            botonVerMas : 'primary',
            botonFlotante : 'primary',
            botonConfirmarPedido : 'primary',
            ultimoPaso : 'secondary',
            sideNav : 'primary',
            iconosRss : 'primary'
		}
	},
    methods : {
        mapMixin(cordenadas){
            if(cordenadas != '' && cordenadas != null){
                window.open(this.googleMaps + cordenadas, "_blank");  
            }
        },
        wspMixin(numero){
            let url = this.linkWsp + numero 
            window.open(url, "_blank");  
        },
        IsNumber(event) {
            if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
        },
        getImageUrl(imageId) {
            return this.rutaImage + imageId
        },
        getImageCategUrl(imageId) {
            return this.rutaImageCat + imageId
        },
        linkMixin(url){   
            window.open(url, "_blank");    
        },
        formatFecha(time) {
			return this.$moment(time).format('DD-MM-YYYY');
		}, 
        /*
        formatHora(time) {
			return this.$moment(time).format('hh:mm a');
		}, 
        */
    }
}
export default misMixins;
