import axios from 'axios'
import VueAxios from 'vue-axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import AxiosPlugin from 'vue-axios-cors';
import {misMixins} from './mixins'
 
const GlobalComponents = {
  install(Vue) {
    Vue.use(VueAxios, axios);
    Vue.use(AxiosPlugin);
    Vue.component('Loading', Loading);
    Vue.use(vueNumeralFilterInstaller, { locale: 'es' });
    Vue.use(require('vue-moment'));
    Vue.mixin(misMixins);
  }
};

export default GlobalComponents;
