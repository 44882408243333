import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    miCarrito : {},
    perfiles : [],
    perfil : [],
    miHorario : [],
    productos : [],
    categorias : [],
    cantidad : 0,
    totalizador : 0,
    formaPagos : ''
  },
  mutations: {
    setPerfiles(state, data){
      state.perfiles = data
    },
    getPerfil(state, data){
      state.perfil = data
      state.formaPagos = data.forma_pagos;
    },
    addHorarios(state, data){
      state.miHorario = data 
    },
    setCarrito(state, payload){
      state.miCarrito[payload.id] = payload
    },
    setProductos(state, payload){
      state.productos = payload 
    },
    setCategorias(state, payload){
      state.categorias = payload 
    },
    delCarrito(state, payload){
      state.miCarrito = payload
      if(state.miCarrito.length === 0){
        state.miCarrito = {}
      }
    },
    setCantidad(state){
     state.cantidad = Object.values(state.miCarrito).reduce((acc, {cant}) => acc + cant, 0);
    },
    setTotaliza(state){
      state.totalizador = Object.values(state.miCarrito).reduce((acc, {total}) => acc + total, 0);
    }
  },
  actions: {
    async getProductos({commit}, url){     
          
        await Vue.axios.get(url)  
        .then((response) => {  
          let datos = response.data.data;
          let categorias = datos.map( function(d){
            return {
              id : d.categoria.id,
              categoria : d.categoria.categoria,
              orden : d.categoria.orden,
              imgCategoria: d.categoria.imagen
            }
          })
          categorias = categorias.sort((a, b) => a.orden - b.orden)
          let hash = {};
          categorias = categorias.filter(o => hash[o.id] ? false : hash[o.id] = true);
          commit('setCategorias', categorias)
          commit('setProductos', datos)
        })
        .catch((e)=>{
            console.log('error terminos' + e);
        })
    },
    async getHorarios({commit}, url){ 
          await Vue.axios.get(url)  
          .then((response) => {  
            let datos = response.data.data;
            let fecha = response.data.hoy;
            commit('addHorarios', datos.find( d => d.fecha == fecha))
          })
          .catch((e)=>{
              console.log('error terminos' + e);
          })
    },
    async miPerfil({commit}, payload){
      const url = payload.server + 'perfil/empresa/' + payload.empresa;
        await Vue.axios.get(url)  
        .then((response) => {  
          if(response.data.data === null){
            commit('setPerfiles',[])
          }else{
            commit('setPerfiles',response.data.data)
          }
        })
        .catch((e)=>{
            console.log('error terminos' + e);
        })
    },
    agregarAlCarro( {commit, state },  [producto, cantidad, observacion] ){
      if(typeof(state.miCarrito) !== 'undefined'){
        producto.cant = cantidad
      }else{
        Object.state.miCarrito.hasOwnProperty.call(producto.id)
        ? producto.cant = state.miCarrito[producto.id].cant + cantidad
        : producto.cant = cantidad
      }
      producto.total = producto.cant * producto.valor
      producto.obs = observacion
      commit('setCarrito', producto) 
      commit('setCantidad') 
      commit('setTotaliza') 
    },
    eliminarDelCarro({commit, state }, item){
      let carro = Object.values(state.miCarrito).filter( carro => carro.id !== item);
      commit('delCarrito', carro) 
      commit('setCantidad') 
      commit('setTotaliza') 
    },
    actualizaElCarro({commit, state }, [producto, cantidad, observacion] ){
        let carro = Object.values(state.miCarrito).find( carro => carro.id === producto.id);
        carro.cant = cantidad
        carro.obs = observacion
        carro.total = carro.valor * cantidad
        commit('setCarrito', carro) 
        commit('setCantidad') 
        commit('setTotaliza') 
    }
  },
 plugins: [createPersistedState()]
})
