<template>
      <v-dialog
        v-model="dialogEdit"
        persistent
        fullscreen
        scrollable
       >
        <v-card>
          <v-card-title class="ml-6 font-weight-bold">
            <v-flex md6 sm12 xs12 offset-md3 >
                <v-layout align-end justify-end>
                    <v-btn icon @click="closeDialog()"  >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
                {{ info.nombre }}
            </v-flex>
          </v-card-title>
            <v-img
            class="mx-auto"
            max-height="250"
            max-width="250"
            :src="getImageUrl(info.imagen)" 
            >
            <v-card-title class="pl-12 pt-12">
            <v-container fill-height fluid >
              <v-layout fill-height >
                <v-flex xs12 sm12 offset-sm6 flexbox>
                  <v-chip
                    class="float-right"
                    color="secondary"
                    x-large
                  >${{ info.valor | numeral('0,0') }}
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-container>
            </v-card-title>
            </v-img>

          <v-card-text class="px-6 mt-4">
            <v-flex md6 sm12 xs12 offset-md3>
              <div v-html="info.descripcion"></div>
              <v-textarea
                    class="mt-5"
                    counter
                    outlined
                    label="¿ Deseas agregar o editar la observación ?"
                    :rules="txtArea"
                    v-model="observacion"
              ></v-textarea>
            </v-flex>

          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
             <v-flex md3 sm6 xs5 offset-md3 class="text-xs-left text-sm-left text-md-left">
                  <v-btn icon outlined class="mx-1" @click="cont--">
                      <v-icon >mdi-minus</v-icon>
                  </v-btn>
                  {{contador}}
                  <v-btn icon outlined class="mx-1" @click="cont++">
                      <v-icon>mdi-plus</v-icon>
                  </v-btn>
              </v-flex>
            <v-flex md3 sm6 xs7 class="text-xs-right text-sm-right text-md-right">
              <v-btn color="primary" block rounded @click="editar(info)">
                ACTUALIZAR  $ {{ sumador | numeral('0,0') }}
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
    </v-dialog>
    
</template>

<script>
export default {
    props: ['info', 'dialogEdit'],
    computed:{
      observacion:{
        set(value){
          this.comentario = value;
        },    
        get(){
          return this.info.obs; 
        }
      },
      contador(){
        return this.info.cant + this.cont
      },
      sumador(){
        return this.cont == 0 ? this.info.valor * this.info.cant : this.info.valor * (this.cont + this.info.cant)        
      }
    },
    watch: {
      cont(val) {
        if( val <= (this.info.cant * - 1)){
            this.cont = this.info.cant * - 1
        }
      }
    },
    data(){
        return {
            comentario : '',
            cont : 0,
            txtArea: [v => v.length <= 100 || 'Campo debe contener máximo 100 caractéres'],
        }
    },
    methods:{
        editar(item){
           let contador = this.cont + this.info.cant > 0 ? true : false 
           if(!contador) return;
           let cantidad = this.cont + this.info.cant;
           let obs = this.comentario == '' ? this.observacion : this.comentario;
           this.$emit('modificar', item, cantidad, obs)
           this.comentario = '';
           this.cont = 0;
        },
        closeDialog(){
          this.cont = 0;
          this.$emit('cerrar-editar');
        },

    }
}
</script>
