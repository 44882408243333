import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('../Home.vue'),
  },
  { 
    path: '/app', 
    name: 'app',
    component: require('../App').default,
    children: [ 
        { 
          path: '/productos', 
          name: 'productos', 
          component: require('../views/Productos').default,
        }, 
        { 
          path: '/detalles', 
          name: 'detalles', 
          component: require('../views/Detalle').default,
        }, 
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
