<template>
  <v-card
          class="elevation-2 mx-3 mb-4 rounded-lg"
          outlined
          max-height='230px'
        >
          <v-btn
            icon
            absolute
            top
            small
            right
          >
          <v-icon color="red" large  v-show="app.destacado!=0" >mdi-heart-circle</v-icon>
            
          </v-btn>
          <v-card-title class="subtitle-2 font-weight-bold">
            {{ getTitulo(app.nombre) }}
          </v-card-title>
          <v-card-subtitle class="caption" v-html="getSubtitulo(app.descripcion)"></v-card-subtitle>
          <v-layout class="pl-5">
            
            <v-flex md6 sm6 xs6>
              <v-card flat >
                <v-avatar
                  size="150"
                  tile
                >
                  <v-img 
                  :src="getImageUrl(app.imagen)" 
                  :lazy-src="getImageUrl(app.imagen)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
              </v-avatar>
              </v-card>
              
            </v-flex>
            <v-flex md6 sm6 xs6 >
               <v-card flat  >
                   <v-card-text>

                      <v-layout align-end justify-end>
                        <span class="title font-weight-bold" color="primary">${{ app.valor | numeral('0,0') }}</span>
                      </v-layout>

                      <v-layout align-end justify-end>
                        <div v-if="app.valor_ant > 0">
                          <div class="caption text-decoration-line-through red--text">
                           Normal ${{ app.valor_ant | numeral('0,0') }}
                          </div>
                        </div>
                        <div v-else>
                          <div class="caption text-decoration-line-through pa-2">
                           
                          </div>
                        </div>
                     </v-layout> 

                      <v-layout align-end justify-end>
                        <v-chip
                          class="my-1"
                          :color="app.estado == 1 ? 'green' : 'red' "
                          dark
                          x-small
                        >
                          {{ getEstado(app.estado) }}
                        </v-chip>
                      </v-layout>
                   </v-card-text>

                    <v-card-actions>
                      <v-btn 
                         :color="botonVerMas" 
                         outlined
                         small
                         block
                        :disabled="app.estado == 0 ? true : false"
                        @click="leerMas(app)">
                          Ver más 
                      </v-btn>
                    </v-card-actions>
               </v-card>

            </v-flex>
        </v-layout>
        </v-card>
</template>

<script>
export default {
    props: ['app'],
    methods :{
        getTitulo(val){
            return val.substr(0,30)
        },
        getSubtitulo(val){
            var demo = val.replace(/(&nbsp;|<([^>]+)>)/ig, ' ')
            return demo.substring(0,30)+" ..."
        },
        getEstado(val){
            return val == 0 ? "Agotado" : "Disponible";
        },
        leerMas(item){
            this.$emit('leer-mas', item)
        },
    }
}
</script>