const GlobalVars = {
  install(Vue) {

    Vue.prototype.$urlExt = process.env.VUE_APP_ADM;
    Vue.prototype.$appName = process.env.VUE_APP_NAME;
    Vue.prototype.$brand = process.env.VUE_APP_BRAND;
    Vue.prototype.$empresa = process.env.VUE_APP_EMPRESA;
    Vue.prototype.$server = process.env.VUE_APP_SERV + "/api/";
    Vue.prototype.$server2 = process.env.VUE_APP_SERV2 + "/api/";
  }
};
export default GlobalVars;
