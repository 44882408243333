<template>
  <div>
    <v-navigation-drawer
      v-model="stateSideBar"
      app
      disable-resize-watcher
      temporary
      touchless
      height="100%"
      :class="sideNav"
    >
      <v-img contain :aspect-ratio="16 / 12" :src="logo_side" :lazy-src="logo_side">
        <v-row align="end" class="lightbox black--text pl-2 fill-height">
        </v-row>
      </v-img>

      <v-divider class="mb-1" />

      <!--  menu  -->
      <v-list dark>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $appName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-icon small @click="wspMixin(perfil.whatsapp)"
                >mdi-whatsapp</v-icon
              >
              +569 {{ perfil.whatsapp }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="my-2" />

      <div class="px-3 mb-3 white--text">
        <v-icon dark>mdi-store</v-icon>
        DIRECCIÓN
        <div class="ml-4 mt-2">{{ perfil.direccion }}</div>
        <v-btn class="mt-1" text dark small @click="mapMixin(perfil.ubicacion)">
          <v-icon dark small>mdi-google-maps</v-icon> Ver ubicación
        </v-btn>
      </div>

      <v-divider class="my-2" />

      <div class="px-3 mb-3 white--text">
        <v-icon dark>mdi-clock</v-icon>
        Atención Online
      </div>
      <div class="ml-3 my-3 px-3 white--text">
        {{ textoHorario }}
      </div>
      <div class="mt-6 px-3" v-show="miHorario.estado == 1">
        <v-btn dark outlined block>{{ atencion }}</v-btn>
      </div>

      <template v-slot:append>
        <v-layout justify-center class="pa-2">
          <v-btn icon dark large @click="linkMixin(perfil.facebook)">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn icon dark large @click="linkMixin(perfil.instagram)">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          <v-btn icon dark large @click="email(perfil.email)">
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </v-layout>
        <v-layout justify-center class="pa-2">
          <v-btn text x-small class="white--text" @click="develop"
            >concarrito.cl</v-btn
          >
        </v-layout>
      </template>
    </v-navigation-drawer>

    <cabecera @state="open_close" :perfil="perfil"></cabecera>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import cabecera from "./cabecera.vue";

export default {
  components: {
    cabecera,
  },
  computed: {
    ...mapState(["perfil", "miHorario"]),
    atencion() {
      return this.miHorario.estado == 1
        ? this.miHorario.hora_desde + " - " + this.miHorario.hora_hasta
        : "";
    },
    textoHorario() {
      return this.miHorario.estado == 0
        ? "Hoy atendiendo las 24 horas"
        : this.miHorario.estado == 1
        ? "Hoy atendiendo en los siguientes horarios"
        : this.miHorario.estado == 2
        ? "Tienda Online Cerrada"
        : "";
    },
  },
  data() {
    return {
      stateSideBar: false,
    };
  },
  created() {
    this.miLLave();
    this.getHorarios(this.urlHorario);
  },
  methods: {
    ...mapActions(["getHorarios"]),
    email(correo) {
      var url = "mailto:" + correo;
      window.open(url, "_blank");
    },
    develop() {
      window.open("https://concarrito.cl", "_blank");
    },
    async miLLave() {
      await this.axios
        .get(this.$server + "tokeniza/genera")
        .then((response) => {
          localStorage.setItem("token", response.data.data);
        })
        .catch((e) => {
          console.log("error terminos" + e);
        });
    },
    open_close() {
      this.stateSideBar = !this.stateSideBar;
    },
  },
};
</script>
