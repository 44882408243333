<template>
  <div>
    
    <loading :active.sync="isLoading" 
        :can-cancel="false" 
        background-color="#FFF"
        color = "#A90501"
        loader="spinner"
        :is-full-page="fullPage">
    </loading>

    <v-layout wrap>

      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right @click="toTop">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>

      <v-flex sm12 xs12 md10 offset-md-1>
          <v-select
            background-color="white"
            :items="categorias"
            label="Categorias"
            item-text="categoria" 
            item-value="categoria" 
            filled
      
            @click:prepend="buscarFnc(1)"
            prepend-icon="mdi-magnify"
            v-model="categ"
            elevation-12
            v-show="esconde"
            @change="escondeCategoria()"
          ></v-select>

          <v-text-field
          background-color="white"
          v-model="search"
          label="Buscar producto ..."
          @click:prepend="buscarFnc(2)"
          prepend-icon="mdi-filter"
          elevation-12
          filled
          clearable
          @click:clear="clearable()"
          v-show="buscar"
        ></v-text-field>
      </v-flex>

      <transition name="fade" v-if="!prodEffect">
        <categoria-component @productos-categoria="productosCategoria" />
      </transition>

      
        <v-flex  md4 sm6 xs12  v-for="app of filtrar" :key="app.id">
          <transition name="fade" v-if="prodEffect">
            <listaProductos  :app="app" @leer-mas="leerMas" />
            </transition>
        </v-flex>
      

      <detalleProducto :info="info" :dialog="dialogLeerMas" @leer-menos="leerMenos" @ver-footer="verFooter" />
      
      <v-bottom-sheet 
       inset 
       v-model="sheet">
        <v-card tile>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div style="font-size:20px">Total</div>
                  </v-list-item-title>
                <v-list-item-subtitle>
                  Pedido
                </v-list-item-subtitle>
                
              </v-list-item-content>
              <v-spacer></v-spacer>
              <v-list-item-icon>
                <div style="font-size:26px">$ {{totalizador | numeral('0,0')}}</div>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-bottom-sheet>

    </v-layout>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import listaProductos from "../components/listaProductos.vue"
import detalleProducto from "../components/detalleProducto.vue"
import categoriaComponent from "../components/Categorias.vue"
export default {
  components:{
    listaProductos,
    detalleProducto,
    categoriaComponent
  },
  data(){
    return {
      isLoading: false,
      fullPage: true,
      dialogLeerMas : false,
      info : [],
      search : '',
      categ : '',
      buscar : false,
      sheet : false,
      fab: false,
      prodEffect: false
    }
  },
  computed :{
    ...mapState(['miCarrito', 'perfil', 'productos', 'categorias', 'totalizador']),
    filtrar() {

      if(this.buscar){
          return this.productos.filter((app) => {
              return app.nombre.toLowerCase().match(this.search.toLowerCase())
          }) 
      }else{
        return this.productos.filter((app) => {
          return app.categoria.categoria.toLowerCase().match(this.categ.toLowerCase())    
        }) 
        
      }
    },
    esconde(){
       return !this.buscar ? true : false
    }
  },
  created(){
    this.categ = this.productos[0].categoria.categoria;
  },
  methods:{
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    clearable(){
      this.buscarFnc(0)
    },
    buscarFnc(acc){
      this.prodEffect = acc == 1 ? true : false;
      this.buscar = acc == 1 ? true : false;
      if (acc == 1) this.search = ''
    },
    leerMas(item){
        var index = this.productos.indexOf(item)
        this.info = this.productos[index]
        this.dialogLeerMas = true
    },
    verFooter(){
      this.sheet = true
      setTimeout(() => { this.sheet = false }, 1000)
    },
    leerMenos(){
      this.dialogLeerMas = false;
    },
    productosCategoria(item){
      this.prodEffect = true
      this.categ = item;
    },
    escondeCategoria(){
      this.prodEffect = true
    }
  }
}
</script>
<style type="text/css">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0
  }
</style>
